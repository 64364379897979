<template>
  <sub-page v-if="$store.state.auth.userType === 'superadmin'"
            title="Бізнес акаунти"
            subtitle="FineTrading"
            icon="mdi-briefcase-outline"
            no-side
  >

    <v-row class="mt-3">
      <v-col cols="4">
        <v-sheet  dark class="wsRoundedHalf pa-5" :color="wsDARKLIGHT" >
          <div class="d-flex align-center">
            <v-img  aspect-ratio="1" max-width="21" class="mr-2" src="@/assets/brand/logo_fine_white_small.png" />
            <h4 class="font-weight-medium">{{ $t('FineTrading') }} : <span class="font-weight-bold">{{ fineLength }} </span></h4>
          </div>
          <div class="d-flex align-center mt-3">
            <v-img  aspect-ratio="1" max-width="25" class="mr-2" src="@/assets/brand/logo_westudy_small_white.png" />
            <h4 class="font-weight-medium">{{ $t('WeStudy') }} : <span class="font-weight-bold">{{ westudyLength }} </span></h4>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="4">
        <v-sheet  dark class="wsRoundedHalf pa-5" :color="wsACCENT" >
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-briefcase-outline</v-icon>
            <h4 class="font-weight-medium">{{ $t('Тріал') }} : <span class="font-weight-bold">{{ trialLength }} </span></h4>
          </div>
          <div class="d-flex align-center mt-3">
            <v-icon class="mr-2">mdi-cash</v-icon>
            <h4 class="font-weight-medium">{{ $t('Платні') }} : <span class="font-weight-bold">{{ payedLength }} </span></h4>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="4">
        <v-sheet  dark class="wsRoundedHalf pa-5" :color="wsSUCCESS" >
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-briefcase-outline</v-icon>
            <h4 class="font-weight-medium">{{ $t('Бізнесів') }} : <span class="font-weight-bold">{{ businesses.length}} </span></h4>
          </div>
          <div class="d-flex align-center mt-3">
            <v-icon class="mr-2">mdi-cash</v-icon>
            <h4 class="font-weight-medium">{{ $t('Місячний план') }} : <span class="font-weight-bold">{{ plannedMonthArrival}} USD</span></h4>
          </div>
        </v-sheet>
      </v-col>
    </v-row>

    <!--  Filters-->
    <v-sheet :color="wsACCENT" dark class="wsRoundedHalf mt-6 pa-3 px-9 ">
      <div class="d-flex justify-space-between align-center">
        <div class="d-flex align-center pr-16 mr-6">
          <h5 class="mr-6 d-flex align-center"> <v-icon class="mr-1" small >mdi-tune-vertical</v-icon> Filters: </h5>
          <v-sheet  class="mr-3 "  color="transparent" width="200">
            <v-chip  class=" font-weight-medium" small outlined>
              <v-icon small>mdi-magnify</v-icon>
              <v-text-field v-model="search" style="font-size: 12px " :placeholder="$t('Search')" height="10" flat solo hide-details background-color="transparent" />
            </v-chip>
          </v-sheet>

          <ft-select v-model="filter.business_plan"  :items="filterBusinessPlans" >
            <template v-slot:default="{text}">
              <v-chip  class="mr-3 font-weight-medium" small outlined>
                {{ text ? text : $t('BusinessPlan') }}
              </v-chip>
            </template>
          </ft-select>

          <ft-select @input="changeTagFilter($event)"  :items="tagsSelectFiltered" >
            <v-chip  class="mr-3 font-weight-medium" small outlined>
              {{ $t('Tags') }}
              <v-icon small>mdi-chevron-down</v-icon>
            </v-chip>
          </ft-select>

          <v-btn icon small @click="filter = {}; filterTags = [], search = '' "><v-icon>mdi-close</v-icon> </v-btn>
        </div>
        <wsChip icon="mdi-briefcase" >
          <h5>{{ $t('Total')}} : {{ items.length }}</h5>
        </wsChip>
      </div>

      <div class="d-flex flex-wrap align-center  mr-6">
        <v-chip v-for="tag in filterTags" :key="tag"  class="mr-3 font-weight-medium px-0 pl-3 mt-3" small outlined>
          {{ getTagName(tag)  }}
          <v-btn @click="changeTagFilter(tag)" small icon> <v-icon small>mdi-close</v-icon></v-btn>
        </v-chip>
      </div>

    </v-sheet>

    <!--  Main View-->
    <v-sheet class="mt-6 wsRoundedHalf pa-6">
      <!--  Businesses table -->
      <v-data-table :items="items" :headers="headers" :search="search" :loading="LOADING" multi-sort >
        <template v-slot:body="{items,index}">
          <tbody>
          <tr @contextmenu="openMenu($event, item)" v-for="(item,i) in items " :key="i">
            <td class="py-2">
              <div class="d-flex">
                <v-avatar class="mr-2" :color="wsACCENT"  size="40" key="profile_picture" >
                  <v-sheet color="transparent" class="pt-1" >
                    <v-img v-if="item.project === 'westudy'" height="30" width="30" src="@/assets/brand/logo_westudy_small_white.png" />
                    <v-img v-else height="20" width="20" src="@/assets/brand/logo_fine_white_small.png" />
                  </v-sheet>
                </v-avatar>
                <div>
                  <h5> {{ item.name }}</h5>
                  <v-chip v-for="(tag,j) in item.tags" :key="'tag_' + index + '_' + j" x-small outlined :color="tag.color" class="px-1">
                    <h6>{{ tag.name }}</h6>
                  </v-chip>
                </div>
              </div>
            </td>
            <td>
              <ws-chip :color="wsSUCCESS" icon="mdi-cash" >
                <h5 :class=" item.business_plan_alias === 'trial' || item.business_plan_alias === 'westudy_trial' ? 'font-weight-light' : ''">
                  {{ item.business_plan_name }}
                </h5>
              </ws-chip>
            </td>
            <td class="text-no-wrap">
              <ws-chip :color="wsACCENT" icon="mdi-calendar" :text="item.days_exists"></ws-chip>
            </td>

            <td class="text-no-wrap">
              <ws-chip :color="item.days_left > 0 ? wsACCENT : 'red lighten-1'" icon="mdi-calendar" >
                <h5 :style="`color : ${item.days_left < 0 ? 'red' : ''}`"> {{ Math.abs(item.days_left) }}  </h5>
              </ws-chip>
            </td>

            <td class="text-no-wrap">
              <ws-chip :color="item.storage_size < item.storage_size_quota ? wsACCENT : 'red lighten-1'" icon="mdi-server">
                <h5 :style="`color : ${item.storage_size > item.storage_size_quota ? 'red' : ''}`">
                  {{ item.storage_size }} / {{ item.storage_size_quota }}</h5>
              </ws-chip>
            </td>

            <td class="text-no-wrap">
              <ws-chip :color="item.student_profiles_total < item.student_profiles_quota ? wsACCENT : 'red lighten-1'" icon="mdi-account-school">
                <h5 :style="`color : ${item.student_profiles_total > item.student_profiles_quota ? 'red' : ''}`">
                  {{ item.student_profiles_total }} / {{ item.student_profiles_quota }}</h5>
              </ws-chip>
            </td>



            <td class="text-no-wrap">
              <ws-chip :color="item.managers_total <= item.managers_quota ? wsACCENT : 'red lighten-1'" icon="mdi-account-school">
                <h5 :style="`color : ${item.managers_total > item.managers_quota ? 'red' : ''}`">
                  {{ item.managers_total }} / {{ item.managers_quota }}</h5>
              </ws-chip>
            </td>

            <td>
              <ft-select left @input="editAction($event, item)" :items="editActionTypes(item)">
                <v-btn icon><v-icon :color="wsATTENTION">mdi-pencil-circle</v-icon></v-btn>
              </ft-select>

            </td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-sheet>

    <template #dialog>
      <ft-select :expanded="displayContextMenu" :x="x" :y="y" absolute  @input="editAction($event, selectedBusiness)" :items="editActionTypes(selectedBusiness)" />
    </template>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "education",

  props : ['user_id'],
  data() {
    return {
      displayContextMenu : false,
      selectedEventBusinessId : null,
      x : 0,
      y : 0,
      project : 'fine',
      businesses : [],
      tagsSelect : [],
      eventType : "Нотатка",
      eventEntity : {
        type : 'note'
      },
      defaultEventEntity : {
        type : ''
      },
      filter : {},
      filterTags : [],
      search : '',


      // dsiplay variables
      displayConfirmArchive : false,
      displayAddEventDialog : false,

      // select variables
      newCardType : 'note',
      selectedBusiness : {},
    }
  },
  computed : {

    navigationSelect() {
      return [
        { text : this.$t('Businesses')     , value : 'businesses'   , path :  ''   , icon : 'mdi-file' ,    } ,
        { text : this.$t('NewClients')    , value : 'new-clients'   , path :  'new-clients'   , icon : 'mdi-page-layout-body' ,    } ,
      ]
    },

    tagsSelectFiltered() {

      let items = this.tagsSelect

      items.forEach(tag => {
        if ( this.filterTags.includes(tag.value)) {
          tag.icon = 'mdi-minus'
        } else {
          tag.icon = 'mdi-plus'
        }
      })

      return items

    },
    items() {
      let items = JSON.parse(JSON.stringify(this.businesses))

      if ( this.filter.crm_status ) {
        items = items.filter( el=> el.crm_status === this.filter.crm_status )
      }

      if ( this.filter.business_plan ) {
        items = items.filter( el=> el.business_plan_alias === this.filter.business_plan )
      }

      if ( this.filterTags.length > 0 ) {
        items = items.filter( el=>
            el.tags.map(tag => tag.uuid).filter(tag_uuid => this.filterTags.includes(tag_uuid)).length === this.filterTags.length
        )
      }

      return items
    },
    headers()  {
      return [
        { text : this.$t('Name')          ,  value : 'name'                             },
        { text : this.$t('BusinessPlan')  ,  value : 'business_plan_alias'              },
        { text : this.$t('Exists')    ,  value : 'days_exists' , align : 'center'       },
        { text : this.$t('DaysLeft')        ,  value : 'days_left'                      },
        { text : this.$t('Storage')         ,  value : 'storage_size'         },
        { text : this.$t('Students')        ,  value : 'student_profiles_total'         },
        { text : this.$t('Managers')        ,  value : 'managers_total'         },

        { text : this.$t('Action')        , sortable : false   }
      ]
    },
    filterStatusTypes() {

      let items = JSON.parse(JSON.stringify(this.statusTypes));
      if ( this.businesses.length > 0 ) {
        items.unshift({ text : this.$t('All') + `(${this.businesses.length})` , value : null })
        items.forEach(item => {
          if ( item.value !== null) {
            let length = this.getFilterLength('crm_status', item.value)
            if ( length > 0 ) {
              item.text = item.text + ` (${length})`
            }
          }
        })
      }

      return items
    },
    filterBusinessPlans() {
      let items = [{ text : this.$t('All') , value : null }]
      let map = this.businesses.map(el=> el.business_plan_alias ).filter( (v, i, a) => a.indexOf(v) === i )
      map.forEach(item => items.push( { text : this.$t('business_plan_' + item ) + ` (${this.getFilterLength('business_plan_alias', item)})`, value : item  }))

      return items
    },
    statusTypes() {
      return [
        {  text: this.$t('Новий')         ,  value: 'new'          },
        {  text: this.$t('Контактували')  ,  value: 'contacted'    },
        {  text: this.$t('Не відповів')   ,  value: 'no_contact'   },
        {  text: this.$t('В процесі')     ,  value: 'in_process'   },
        {  text: this.$t('Чекають')       ,  value: 'waiting'   },
        {  text: this.$t('Перспективний') ,  value: 'perspective'   },
        {  text: this.$t('Cоціальний')    ,  value: 'social'   },
        {  text: this.$t('Успіх')         ,  value: 'success'      },
        {  text: this.$t('Відмова')       ,  value: 'refuse'       },
      ]

    },
    plannedMonthArrival() {
      let ammount = 0;
      if (this.businesses.length > 0 ) {
        let array =  this.businesses.map(el => el.business_plan_price  )
        array.forEach((price)=>{
          ammount += price
        })
      }
      return ammount
    },
    trialLength() {
      let quantity = 0;
      if (this.businesses.length > 0) {
        let array = this.businesses.filter(el=> el.business_plan_price === 0 )
        quantity = array.length
      }
      return quantity
    },
    payedLength() {
      return this.businesses.length - this.trialLength
    },
    fineLength() {
      let quantity = 0;
      if (this.businesses.length > 0) {
        let array = this.businesses.filter(el=> el.project !== 'westudy' )
        quantity = array.length
      }
      return quantity
    },
    westudyLength() {
      return this.businesses.length - this.fineLength
    },

  },
  methods : {

    changeTagFilter(tag) {
      if ( this.filterTags.includes(tag)) {
        let index = this.filterTags.findIndex(el => el === tag)
        if ( index === -1 ) { return }
        this.filterTags.splice(index,1)
      } else {
        this.filterTags.push(tag)
      }
    },
    getTagName(tag) {
      let tagEntity = this.tagsSelect.find(el => el.value === tag)
      if ( tagEntity ) { return tagEntity.text }
      return 'tag'
    },

    ...mapActions('adminCrmSystem', [
      'ADMIN_CRM_EDIT_BUSINESS_TAGS',
      'ARCHIVE_BUSINESS' ,
    ] ),

    ...mapActions('adminPayments', [
      'GET_BUSINESSES',
    ]),

    openMenu($event , item) {
      this.selectedBusiness = item
      $event.preventDefault()
      this.displayContextMenu = false
      this.x = $event.clientX
      this.y = $event.clientY
      this.$nextTick(() => {
        this.displayContextMenu = true
      })
    },
    editAction(type,item) {
      this.selectedBusiness = item
      switch(type) {
        case 'archive_business' : this.displayArchiveBusiness(); break;
      }

      if (type.includes('add_tag_') ) {
        this.notify( 'ADD UUID: ' + type.replace('add_tag_',''))
        this.changeTag(type.replace('add_tag_',''),'add')
      }
      if (type.includes('remove_tag_')) {
        this.changeTag(type.replace('remove_tag_',''),'remove')
      }
    },
    async changeTag(tag,type) {
      let tags = this.selectedBusiness.tags
      let tagsArray = tags.map(el => el.uuid)
      if ( type === 'add' ) {
        tagsArray.push(tag)
      }
      if ( type === 'remove' ) {
        let index = tagsArray.findIndex( el => el === tag )
        if ( index === -1 ) { return }
        tagsArray.splice(index,1)
      }

      let data = {
        business_id : this.selectedBusiness.uuid,
        tags : tagsArray
      }

      let result = await this.ADMIN_CRM_EDIT_BUSINESS_TAGS(data);
      if ( !result ) { return }
      this.selectedBusiness.tags = result
      this.notify('success')

    },

    // technical

    getFilterLength(field, value) {
      if ( this.businesses.length === 0 ) { return }
      return this.businesses.map(el=>el[field]).filter(el=>el === value ).length
    },
    editActionTypes(item) {
      return [
        { text: 'Переглянути картку' , icon : 'mdi-eye'   ,  route : this.localeLink('dashboard/businesses/' + item.uuid) },
        { text: 'Переглянути сайт'   , icon : 'mdi-web'   ,  route : this.localeLink( item.alias) },
        { text: this.$t('Tags'),  icon : 'mdi-tag', children : this.getBusinessFreeTagsSelect(item.tags) },
      ]
    },
    getBusinessFreeTagsSelect(tags) {

      let items = JSON.parse(JSON.stringify(this.tagsSelect) )

      if (!tags ) { return [] }

      let  tagsArray = tags.length > 0 ?  tags.map( el => el.uuid ) : []

      items.forEach((item) => {
        if ( tagsArray.includes(item.value) ) {
          item.value = 'remove_tag_' + item.value
          item.icon = 'mdi-minus'
        } else {
          item.value = 'add_tag_'    + item.value
          item.icon = 'mdi-plus'
        }
      })


      return items
    },

    async initPage() {
      let result = await this.GET_BUSINESSES(this.project);
      if (!result ) { return }
      this.businesses = result.businesses
      this.tagsSelect = result.tags
    }
  },
  async mounted() {
    this.project = this.$store.state.project === 'westudy' ? 'westudy' : 'all'

    this.initPage()

  }
}
</script>

<style scoped lang="scss">
</style>
